<template>
  <PageLayout :sidebar-links="sidebarLinks" title="Файлы" />
</template>

<script>
import { getSections } from "./shared/sections";
import PageLayout from "@/components/PageLayout";
export default {
  name: "index",
  components: {
    PageLayout,
  },

  computed: {
    user() {
      return this.$store.state.user.user;
    },
    sidebarLinks() {
      const scope = this.user && this.user.scopes[0];
      return getSections(scope);
    },
  },
};
</script>
